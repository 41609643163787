<template>
  <!-- begin::Card -->
  <div class="card bg-light shadow">
    <!--begin::Card header-->
    <div class="card-header border-0">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div @click="backUrl" class="text-default cursor-pointer">
          <i class="fw-bolder bi bi-arrow-left fs-3 me-4"></i>
          <span>Detail Data DPC</span>
        </div>
        <!-- end::Nav -->
      </div>
      <!-- end::Card title -->
    </div>
    <!-- end::Card header -->

    <div class="card-body" v-if="detail">
      <div class="card mb-5">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="row mb-7">
                <label class="col-sm-5 col-form-label">Nama DPC</label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    readonly
                    class="form-control form-control-solid"
                    :value="detail.candidate.name"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-2 d-none d-md-block"></div>
            <div class="col-md-5">
              <div class="row mb-7">
                <label class="col-sm-5 col-form-label">Target KTA</label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    readonly
                    class="form-control form-control-solid"
                    :value="detail.kta_candidate.target"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-sm-5 col-form-label">Jumlah KTA</label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    readonly
                    class="form-control form-control-solid"
                    :value="detail.kta_candidate.count"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="row mb-7">
                <label class="col-sm-5 col-form-label">Nama Kecamatan</label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    readonly
                    class="form-control form-control-solid"
                    :value="detail.kta_candidate.dapil"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-sm-5 col-form-label">KTA Kecamatan</label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    readonly
                    :value="detail.kta_candidate.count"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-7 ms-md-15 border-left">
              <div class="table-responsive mt-10 mt-md-0">
                <table class="table table-row-bordered table-striped">
                  <thead class="fw-bolder">
                    <th>Kelurahan</th>
                    <th>Jumlah</th>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data" :key="i">
                      <td>{{ item.territory.name }}</td>
                      <td>{{ item.kta_count_candidate.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Card -->
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import CryptoJS from "crypto-js";
export default {
  props: ["id", "dataDetail"],
  setup(props) {
    const router = useRouter();
    const store = useStore();

    store.commit("SET_ACTIVE_MENU", "ktanisasi");

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    if (!dataId) {
      router.push({ name: "ktanisasi.caleg" });
    }

    store.dispatch("AllKTACountCandidateDashboard", {
      kta_candidate_id: dataId,
    });

    const backUrl = () => {
      router.go(-1);
    };

    const detail = ref(props.dataDetail);

    onMounted(() => {
      if (!props.dataDetail) {
        store
          .dispatch("detailKTACandidateDashboard", { kta_candidate_id: dataId })
          .then(() => {
            detail.value = store.state.KTACandidateDashboardModule.detail;
          })
          .catch(() => {
            Swal.fire("Error", "Data tidak ditemukan", "error");
            setTimeout(() => {
              router.push({ name: "ktanisasi.caleg" });
            }, 500);
          });
      }
      // console.log(detail.value);
    });

    const data = computed(
      () => store.state.KTACountCandidateDashboardModule.all
    );

    return {
      backUrl,
      data,
      detail,
    };
  },
};
</script>
